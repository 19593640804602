@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Rubik:wght@400;500;600;700&display=swap');


body {
    font-family: 'Roboto Condensed', sans-serif;
}


#root {
    overflow: hidden;
}

html {

    .payment {
        width: 400px;


    }


    .pay_box {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .off_sale_2 {
        margin: 20px;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 76px;
        line-height: 100px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #02897A;

        span {
            margin-bottom: 20px;
            margin-left: 15px;
            font-size: 30px;
            line-height: 35px;
            letter-spacing: 0.1px;
        }
    }

    .off_btn {
        text-decoration: none;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #730046;
        border-radius: 14px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        height: 58px;
    }


    .for_Abdulaziz {
        margin-top: 20px;
        cursor: pointer;
        width: 100%;
        border: none;
    }

    .for_Abdulaziz2 {
        margin-top: 10px;
        cursor: pointer;
        width: 100%;
        border: none;
    }

    .for_zabron {
        width: 100%;
        /* width: 50%; */
        border-radius: 14px;
        padding: 10px;
        font-size: 24px;
        color: aliceblue;
        transition: 0.4s all ease-in;
        cursor: pointer;

        .input_Ab {
            margin-top: 10px;
            font-size: 17px;
            font-weight: 500;
            padding: 8px;
            border: none;
            outline: none;
            border: 2px solid #02897A;
            border-radius: 5px;
            height: 55px;
            width: 100%;
        }
    }

    .off_btn_2 {
        margin-top: 20px;
        text-decoration: none;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #075354;
        border: 2px solid #075354;
    }



    .pay_main {
        display: flex;
        align-items: center;

        .pay_img_box {
            width: 220px;
            height: 80px;

            min-height: 90px;
            border: 2px solid transparent;
            padding: 10px;

            img {
                width: 100%;
                height: 100%;
            }

            @media(max-width:576px) {
                width: 180px;
            }


        }


        input {
            margin-top: 10px;
            border-radius: 50%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #730046;
            transition: 0.2s all linear;

            &:checked {
                border: 6px solid #730046;

            }
        }
    }

    .modalcha {
        position: relative;
        padding: 20px;
        padding-top: 60px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
        z-index: 1100;
        width: 1400px;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95));
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media(max-width:1500px) {
            width: 100%;
        }

        .card_number,
        .amal_qilish,
        .password_number {
            display: flex;
            flex-direction: column;
            align-items: center;


        }

        .modal_name,
        .message,
        .payment,
        .email,
        .phone,
        .familiya,
        .card_number,
        .amal_qilish,
        .password_number {
            @media(max-width:576px) {
                width: 100%;
            }
        }

        .inp_name {
            border: 2px solid #730046;
            font-size: 17px;
            font-weight: 500;
            height: 65px;
            margin-bottom: 20px;
            outline: none;
            padding: 10px;
            width: 400px;

            @media(max-width:576px) {
                width: 100%;
            }
        }

        .inp_btn {
            text-decoration: none;
            border: 3px solid #730046;
            color: #730046;
            display: inline-block;
            padding: 0px 0px;
            height: 47px;
            padding: 0px 42px;
            background-color: transparent;
            line-height: 44px;
            text-transform: uppercase;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 0.67px;
            position: relative;
            transition: 0.4s;

            @media(max-width:576px) {
                width: 100%;
                height: 60px;

            }

            &:hover {
                color: #fff;

                &::after {
                    width: 100%;
                    height: 100%;
                }
            }

            &::after {
                z-index: -1;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0%;
                height: 0%;
                background-color: #730046;
                transition: 0.4s;
            }

        }


    }

    .shadow {
        position: fixed;
        z-index: 999;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
    }


    .ss {
        padding: 50px 0;
    }

    .slide2_box {
        .slide2_img {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .slide2_name {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 10px;
            font-size: 40px;
            line-height: 34px;
            font-weight: 600;
        }

        .slide2_h {
            padding-bottom: 20px;
            padding-left: 10px;
            font-size: 22px;
            line-height: 28px;
        }

        .slide2_p {
            padding-bottom: 20px;
            padding-left: 10px;
            font-size: 22px;
            line-height: 28px;
        }

        .slide2_btn {
            margin-left: 10px;
            text-decoration: none;
            border: 3px solid #730046;
            color: #730046;
            margin-bottom: 60px px;
            display: inline-block;
            padding: 0px 0px;
            height: 47px;
            padding: 0px 42px;
            background-color: transparent;
            line-height: 44px;
            text-transform: uppercase;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 0.67px;
            position: relative;
            transition: 0.4s;

            &:hover {
                color: #fff;

                &::after {
                    width: 100%;
                    height: 100%;
                }
            }

            &::after {
                z-index: -1;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0%;
                height: 0%;
                background-color: #730046;
                transition: 0.4s;
            }
        }
    }

    ::selection {
        background-color: #730046;
        color: #fff;
    }

    img {
        user-select: none;
    }

    scroll-behavior: smooth;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: rgb(209, 209, 209);
        display: inline-block;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #730046;
    }

    @media(max-width:992px) {

        ::-webkit-scrollbar-track {
            background: transparent;
            display: inline-block;
        }

        ::-webkit-scrollbar {
            width: 0px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: transparent;
            border-radius: 0px;
        }


    }
}


.Navbar {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 80px;
    background-color: #730046;
    display: flex;
    align-items: center;
    z-index: 999;

    .nav_lang_box {
        display: flex;
        align-items: center;

        .nav_lang {
            cursor: pointer;
            margin-right: 10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid transparent;
            transition: 0.3s;


            &:hover {
                border: 2px solid #fff;

            }

            img {
                width: 100%;
                height: 100%;
            }

        }
    }

    .nav_a {
        margin-left: 20px;
        color: #fff;
        padding: 5px;
        border: 2px solid transparent;
        border-radius: 20px;
        text-transform: uppercase;
        font-size: 18px;
        text-decoration: none;
        transition: all 0.3s ease 0s;

        &:hover {
            border: 2px solid #fff;

        }


    }

}


.Header {
    background-image: url(/image/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
    padding-bottom: 60px;

    .head_logo {
        margin-top: 100px;

        @media(max-width:576px) {
            margin-top: 150px;
            width: 100%;
        }

    }

    .head_name {
        text-align: center;
        position: relative;
        color: #fff;
        font-family: 'Rubik', sans-serif !important;
        text-transform: uppercase;
        text-shadow: 5px 5px 0px #730046;
        font-size: 55px;
        // background: radial-gradient(#730046 1px, transparent 10px);

        @media(max-width:576px) {
            font-size: 36px;
            line-height: 50px;
        }

        &::after {
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 120px;
            background-color: #730046;
            position: absolute;
            content: '';
        }

    }

    .head_box {
        display: flex;
        flex-direction: column;
        align-items: center;

        .head_btn {
            z-index: 99;
            text-decoration: none;
            border: 3px solid #730046;
            color: #730046;
            margin-bottom: 60px;
            display: inline-block;
            padding: 0px 0px;
            height: 47px;
            padding: 0px 42px;
            background-color: transparent;
            line-height: 44px;
            text-transform: uppercase;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 0.67px;
            position: relative;
            transition: 0.4s;

            &:hover {

                &::after {
                    width: 100%;
                    height: 100%;
                }
            }

            &::after {
                z-index: -1;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0%;
                height: 0%;
                background-color: #fff;
                transition: 0.4s;
            }
        }
    }
}

.About {
    padding: 60px;
    padding-top: 100px;
    min-height: calc(100vh - 80px);

    @media(max-width:992px) {
        padding: 60px 15px;
    }

    .about_name {
        position: relative;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 8.95px;
        text-transform: uppercase;
        margin-bottom: 140px;

        &::after {
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 120px;
            background-color: #730046;
            position: absolute;
            content: '';
        }
    }

    .ab_box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .ab_top {

            .ab_top_img {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: 10px solid #ccccff;
                width: 92px;
                height: 92px;

                img {
                    width: 60px;
                    height: 60px;
                }
            }

        }

        .ab_mid {
            .ab_h {
                margin: 25px 0;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 3.52px;
            }
        }

        .ab_down {
            .ab_p {
                text-align: center;
                color: #868585;
                font-size: 15px;
                font-weight: 300;
                line-height: 26px;
            }
        }
    }
}

.Abroad {
    background-color: #f7f7f7;
    min-height: 100vh;
    display: flex;
    align-items: center;

    @media(max-width:992px) {
        padding: 20px 0;
    }

    .abr_img {
        width: 250px;
        height: 250px;

        @media(max-width:992px) {
            width: 200px;
            height: 200px;

        }

    }

    .abr_h {
        margin: 25px 0;
        color: #353738;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        letter-spacing: 3.6px;
    }

    .abr_p {
        text-align: center;
        color: #848789;
        font-size: 18px;
        line-height: 34px;
    }
}

.Gallery {
    padding: 60px 0;
    padding-top: 100px;
    overflow: hidden;

    .col-lg-4 {
        padding: 0;
    }

    .gal_img {
        width: 100%;
        height: 100%;
    }

    .gal_name {
        position: relative;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 8.95px;
        text-transform: uppercase;
        margin-bottom: 100px;

        &::after {
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 120px;
            background-color: #730046;
            position: absolute;
            content: '';
        }
    }

    .gal_btn {
        margin-top: 80px;
        text-decoration: none;
        border: 3px solid #730046;
        color: #730046;
        display: inline-block;
        padding: 0px 0px;
        height: 47px;
        padding: 0px 42px;
        background-color: transparent;
        line-height: 44px;
        text-transform: uppercase;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 0.67px;
        position: relative;
        transition: 0.4s;

        &:hover {
            color: #fff;

            &::after {
                width: 100%;
                height: 100%;
            }
        }

        &::after {
            z-index: -1;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0%;
            height: 0%;
            background-color: #730046;
            transition: 0.4s;
        }
    }
}



.Partner {
    padding: 100px 0 30px;
    background-image: url(/image/part.jpg);
    background-size: cover;
    background-attachment: fixed;

    .part_name {
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        color: #730046;
        text-align: center;
        font-weight: 500;
    }

    .part_img {
        width: 100%;
    }
}

.Contact {
    padding-top: 100px;
    padding-bottom: 30px;

    .con_name {
        position: relative;
        text-align: center;
        font-size: 34px;
        font-weight: 700;
        letter-spacing: 8.95px;
        text-transform: uppercase;
        margin-bottom: 100px;

        &::after {
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 120px;
            background-color: #730046;
            position: absolute;
            content: '';
        }
    }

    .con_inp_box {
        gap: 30px;
        width: 450px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media(max-width:576px) {
            width: 100%;
            flex-direction: column;
        }
    }

    .con_inp_main {
        width: 100%;
        margin-bottom: 70px;
    }

    .con_inp_name {
        text-align: end;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .con_inp {
        border: none;
        height: 50px;
        border-radius: 0 !important;
        width: 100%;
        display: block;
        font-size: 15px;
        padding: 0px 20px;
        border-top: 3px solid #000;
        background-color: #f8f7f7;
    }

    .con_inp_2 {
        padding: 12px;
        border: none;
        min-height: 150px;
        max-height: 150px;
        border-radius: 0 !important;
        width: 100%;
        display: block;
        font-size: 15px;
        padding: 0px 20px;
        border-top: 3px solid #000;
        background-color: #f8f7f7;
    }

    .con_check {
        display: flex;
        align-items: center;

        .inp_ratio_name {
            margin-left: 10px;
            font-size: 16px;
        }
    }

    .inp_ratio {
        appearance: none;
        border: 2px solid #730046;
        height: 25px;
        margin-right: 10px;
        transition: all .2s linear;
        width: 25px;

        &:checked {
            border: 8px solid #730046;
        }
    }

    .con_btn {
        margin-top: 30px;
        text-decoration: none;
        border: 3px solid #730046;
        color: #730046;
        display: inline-block;
        padding: 0px 0px;
        height: 47px;
        padding: 0px 42px;
        background-color: transparent;
        line-height: 44px;
        text-transform: uppercase;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 0.67px;
        position: relative;
        transition: 0.4s;

        &:hover {
            color: #fff;

            &::after {
                width: 100%;
                height: 100%;
            }
        }

        &::after {
            z-index: -1;
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0%;
            height: 0%;
            background-color: #730046;
            transition: 0.4s;
        }
    }

    .con_h {
        margin-bottom: 25px;
        width: 260px;
        font-size: 14px;
        font-weight: 300;
        line-height: 26px;

        @media(max-width: 992px) {
            margin-top: 20px;
            text-align: center;
        }
    }

    .con_soc {
        margin-top: 25px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;

        .con_soc_a {
            margin-right: 15px;
            width: 40px;
            height: 40px;

            img {
                width: 100%;
            }

        }


    }

    .con_tel {
        text-decoration: none;
        color: #000;
        font-size: 16px;
        font-weight: 700;
    }

    .con_mail {
        text-decoration: none;
        color: #000;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 700;
    }

    .con_adres {
        width: 260px;
        font-size: 16px;

        @media(max-width: 992px) {
            text-align: center;
        }
    }

    .maps_map {
        margin-top: 80px;
        width: 100%;
        height: 500px;
        box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }

}

.Footer {
    background-color: #730046;
    padding: 45px 0;

    .foot_a {
        margin-top: 30px;
        margin-bottom: 30px;
        text-decoration: none;
        margin-right: 15px;
        margin-left: 15px;
        color: #ffffff;
        font-size: 20px;
        font-weight: 400;

        @media(max-width:576px) {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    .foot_h {
        @media(max-width:576px) {
            margin-top: 20px;
        }

        text-align: center;
        color: #ffffff;
        font-size: 16px;
    }
}

.Abroad_3 {
    background-attachment: fixed;
    background-image: url(/image/ab_back.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;

    .branch_img_3 {
        width: 700px;
    }

    .branch_box {
        width: 700px;
        background-color: #fff;
        display: flex;
        flex-direction: column;

        @media(max-width:576px) {
            width: 100%;
            padding: 40px 20px;
        }

        .branch_logo {
            object-fit: cover;
            width: 200px;
            height: 227px;
        }

        .branch_h {
            margin-top: 10px;
            margin-bottom: 20px;
            color: #353738;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 3.6px;
        }

        .branch_p {
            color: #848789;
            text-align: justify;
            font-size: 16px;
            line-height: 30px;
        }

    }
}

.Branch,
.Abroad_2 {
    .branch_img {
        width: 100%;
        height: 100%;
    }

    .col-lg-7,
    .col-lg-5 {
        padding: 0;
    }

    .branch_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px;
        padding-top: 50px;

        @media(max-width:576px) {
            padding: 40px 20px;
        }

        .branch_logo {
            object-fit: cover;
            width: 200px;
            height: 227px;
        }

        .branch_h {
            margin-top: 10px;
            margin-bottom: 20px;
            color: #353738;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 3.6px;
        }

        .branch_p {
            color: #848789;
            text-align: justify;
            font-size: 16px;
            line-height: 30px;
        }

    }
}

.nav_a_box {
    @media(max-width:992px) {
        visibility: hidden;
        right: 0;
        top: 0;
        position: fixed;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center !important;
        transition: 0.4s;
        opacity: 0;
        background-color: #730046;
    }

    @media(max-width:450px) {
        width: 100%;
    }

    &.active {
        opacity: 1;
        transform: translateX(0%);
        visibility: visible;

    }
}

.burger {
    // position: relative;
    width: 30px;
    height: 30px;
    margin-right: 25px;
    transition: all .5s ease-in-out;
    z-index: 999999;
    position: absolute;
    top: 55px;
    right: 3%;
    transform: translateY(-50%);


    .burger_up,
    .burger_down {
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: #fff;
        border-radius: 12px;
        transition: all .5s ease-in;
    }

    .burger_up {
        left: -8px;
        width: 50px;
        top: 0;
    }

    .burger_down {
        top: 12px;
        transition: all .3s ease-in-out;
    }

    &.burgered {
        // transform: rotate(-180deg);

        .burger_up {
            transform: translateY(10px) rotate(45deg)
        }

        .burger_down {
            transform: translateY(-2px) rotate(-45deg);
        }

        .burger_up {
            width: 30px;
            left: 14px;
        }
    }
}

.slider {
    padding: 100px 10px;

    .swiper-pagination-bullet {
        border-radius: 5px;
        width: 15px;
        height: 5px;
        background-color: #730046;
    }

    .swiper-button-prev:after {
        color: #730046;
    }

    .swiper-button-next:after {
        color: #730046;
    }

    .slider_box {
        // padding-bottom: 20px;
        height: 600px;
        border: 1px solid #730046;

        &:hover {

            .slid_img {
                img {
                    transform: scale(1.2);

                }
            }
        }

        .slid_img {
            width: 100%;
            height: 350px;
            overflow: hidden;

            img {
                transition: 0.4s;
                width: 100%;
                height: 100%;
            }
        }

        .slide_name {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 10px;
            font-size: 26px;
            line-height: 34px;
            font-weight: 600;
        }

        .slide_h {
            padding-bottom: 20px;
            padding-left: 10px;
            font-size: 22px;
            line-height: 28px;
        }

        .slide_p {
            padding-bottom: 20px;
            padding-left: 10px;
            font-size: 22px;
            line-height: 28px;
        }

        .slide_btn {
            cursor: pointer;
            margin-left: 10px;
            text-decoration: none;
            border: 3px solid #730046;
            color: #730046;
            margin-bottom: 60px px;
            display: inline-block;
            padding: 0px 0px;
            height: 47px;
            padding: 0px 42px;
            background-color: transparent;
            line-height: 44px;
            text-transform: uppercase;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 0.67px;
            position: relative;
            transition: 0.4s;

            &:hover {
                color: #fff;

                &::after {
                    width: 100%;
                    height: 100%;
                }
            }

            &::after {
                z-index: -1;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0%;
                height: 0%;
                background-color: #730046;
                transition: 0.4s;
            }
        }
    }
}

.x {
    cursor: pointer;
    position: absolute;
    top: 60px;
    right: 20px;
    width: 30px;
    height: 30px;

}

.x_1 {
    content: '';
    width: 40px;
    height: 3px;
    background-color: #730046;
    transform: rotate(45deg);
}

.x_2 {
    content: '';
    width: 40px;
    height: 3px;
    background-color: #730046;
    transform: rotate(-45deg) translate(2px, -2px);
}
.for_image img {
    width: 100%;

}

.text_city {
    text-align: center;
    font-size: 44px;
    font-weight: 600;
}

.city_p {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    text-align: left;
}

.span_text {
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    color: #730046;
}

.ss ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    color: #000000;
    align-items: flex-start;
}

.for_price_text {
    /* background-color: red; */
}

.for_price_text h2 {
    text-align: left;
    font-size: 24px;
    color: #730046;
}

@media(max-width: 512px) {
    .text_city {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
    }
}

.btn_sliderrr {
    width: 100%;
    /* width: 50%; */
    border-radius: 14px;
    border: 1px solid #4e0431;
    padding: 10px;
    font-size: 24px;
    color: aliceblue;
    background-color: #730046;
    transition: 0.4s all ease-in;
    cursor: pointer;
}

.btn_sliderrr:hover {
    background-color: #ffffff;
    color: #730046;

}